import React from "react";

export default function Restaurant() {
  return (
    <svg style={{ height: 100, width: 100 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" id="restaurant">
      <g>
        <path
          fill="pink"
          d="M14.734,21.606a1.494,1.494,0,0,1-1.256-.7,64.446,64.446,0,0,1-3.913-7.1L9.131,13.7A2.794,2.794,0,0,1,7.257,12L5.377,7.065a2.782,2.782,0,0,1,.194-2.411l.238-.41a.969.969,0,0,1,.842-.48h.012a.975.975,0,0,1,.846.5l8.544,15.164a1.4,1.4,0,0,1,.115,1.113,1.509,1.509,0,0,1-.7.869A1.487,1.487,0,0,1,14.734,21.606ZM6.651,4.514a.223.223,0,0,0-.195.11l-.237.408A2.037,2.037,0,0,0,6.078,6.8l1.881,4.936a2.037,2.037,0,0,0,1.368,1.243l.6.158a.38.38,0,0,1,.243.2,64.576,64.576,0,0,0,3.94,7.165.737.737,0,0,0,.99.257.748.748,0,0,0,.354-.436A.657.657,0,0,0,15.4,19.8L6.856,4.634A.219.219,0,0,0,6.651,4.514Z"
        ></path>
        <path
          fill="pink"
          d="M8.444 21.606a1.44 1.44 0 0 1-.767-.219 1.479 1.479 0 0 1-.542-1.975c.856-1.575 1.878-3.281 2.956-4.938a.375.375 0 0 1 .629.41C9.653 16.523 8.641 18.213 7.8 19.768a.734.734 0 0 0 .272.979.717.717 0 0 0 .982-.22c.719-1.086 1.479-2.321 2.262-3.675a.375.375 0 0 1 .649.375c-.791 1.367-1.558 2.615-2.282 3.709A1.463 1.463 0 0 1 8.444 21.606zM11.776 12.979a.371.371 0 0 1-.375-.37V12.6a.381.381 0 0 1 .065-.212c.555-.808 1.03-1.483 1.382-1.982A1.592 1.592 0 0 1 12.5 8.377l2.88-4.8a.376.376 0 0 1 .644.387l-2.88 4.8a.831.831 0 0 0 .292 1.146l.133.082a.374.374 0 0 1 .17.244.371.371 0 0 1-.061.291l-.1.15c-.356.5-.868 1.229-1.478 2.118A.377.377 0 0 1 11.776 12.979zM12.945 15.045A.378.378 0 0 1 12.768 15a.375.375 0 0 1-.153-.507c.757-1.413 1.434-2.728 1.812-3.516a.375.375 0 0 1 .233-.2.365.365 0 0 1 .3.04l.13.08a.831.831 0 0 0 .633.1.82.82 0 0 0 .508-.379l2.88-4.8a.376.376 0 0 1 .644.387L16.877 11a1.567 1.567 0 0 1-.97.72 1.6 1.6 0 0 1-.976-.068c-.4.807-1 1.963-1.655 3.191A.374.374 0 0 1 12.945 15.045z"
        ></path>
        <path
          fill="pink"
          d="M14.767 8.53a.378.378 0 0 1-.193-.054.375.375 0 0 1-.129-.513l2.181-3.638a.375.375 0 0 1 .644.385L15.089 8.347A.372.372 0 0 1 14.767 8.53zM16.01 9.275a.368.368 0 0 1-.192-.054.374.374 0 0 1-.129-.513L17.87 5.07a.375.375 0 1 1 .643.385l-2.18 3.637A.374.374 0 0 1 16.01 9.275z"
        ></path>
      </g>
    </svg>
  );
}
