import logo from "./logo.svg";
import "./App.css";
import styled from "styled-components";
import Restaurant from "./restaurant";
import Gift1 from "./Gift1";
import { useEffect, useState } from "react";
import Confetti from 'react-dom-confetti';

function App() {
  return (
    <AppFrame>
      <h1 style={{ color: "pink", margin: 0, padding: 0, width: "100%", textAlign: "center", paddingTop: 100, paddingBottom: 40 }}>
        🎉🎉😍😍 Tuppi fyller 28 år 😍😍🎉🎉
      </h1>
      <h1 style={{ color: "pink", margin: 0, padding: 0, width: "100%", textAlign: "center", paddingTop: 0, paddingBottom: 40 }}>
        ❤️ Gratulerer så mye fineste ❤️
      </h1>
      <Frame>
        <CirclePicture alt="pre-forhold" src="/pre.png" />
        <CirclePicture alt="post-forhold-1" src="/post1.png" />
        <CirclePicture alt="post-forhold-2" src="/post2.png" />
        <CirclePicture alt="post-forhold-3" src="/post3.png" />
        <CirclePicture alt="post-forhold-4" src="/post4.png" />
      </Frame>
      <h1 style={{ color: "pink", margin: 0, padding: 0, width: "100%", textAlign: "center", paddingTop: 100, paddingBottom: 40 }}>
        Trykk på boksen for å vise hva som ligger under 🤠
      </h1>
      <Frame>
        <GiftBox Icon={Restaurant} giftText="Hvor skal vi spise?" revealText={"Hos Thea"} />
      </Frame>

      <h1 style={{ color: "pink", width: "100%", margin: 0, padding: 0, width: "100%", textAlign: "center", paddingTop: 40, paddingBottom: 40 }}>
        ❤️ Jeg er så heldig som har deg!! Tenk alt vi har opplevd sammen! Og alt vi skal få oppleve sammen i tiden fremover, med leilighet og så mye annet ❤️ Årets gaver er nettopp noe rundt nye opplevelser sammen ❤️ 
      </h1>

      <h1 style={{ color: "pink", margin: 0, padding: 0, width: "100%", textAlign: "center", paddingTop: 40, paddingBottom: 40 }}>
        Velg en gave ❤️
      </h1>
      <Frame>
      <GiftBox Icon={Gift1} giftText="Gave 1" revealText={"Roadtrip til Støren (eller et annet sted) med overnattinger på veien"} />
      <GiftBox Icon={Gift1} giftText="Gave 2" revealText={"Jeg lager en fire-retters med tilhørende vin"} />
      <GiftBox Icon={Gift1} giftText="Gave 3" revealText={"Kulturell aktivitet (kino, museum, konsert eller noe annet)"} />
      </Frame>
      <h1 style={{ color: "pink", margin: 0, padding: 0, width: "100%", textAlign: "center", paddingTop: 40, paddingBottom: 40 }}>
        Elsker deg så høyt ❤️ Håper du får en nydelig dag ❤️
      </h1>
    </AppFrame>
  );
}

function GiftBox({ Icon, giftText, revealText }) {
  const [isOpenBox, setIsOpenBox] = useState(false);
  const [fireConfetti, setFireConfetti] = useState(false); 

  useEffect(() => {
    if (isOpenBox) {
      setTimeout(() => setFireConfetti(true), 2000)
    }
  }, [isOpenBox])

  return (
    <div>
      <GiftPictureFrame>
        <GiftPictureFrame className={isOpenBox ? "opened" : "notopened"} onClick={() => setIsOpenBox(true)} style={{ border: "none", position: "absolute" }}>
          <Icon />
          <p style={{ fontWeight: 700, color: "pink" }}>{giftText}</p>
        </GiftPictureFrame>
      <span style={{ color: "pink", fontWeight: 700, textAlign: "center" }}>{revealText}</span>
      </GiftPictureFrame>
      <Confetti active={ fireConfetti } config={{ spread: 360, elementCount: 150 }} />
    </div>
  );
}

const GiftPictureFrame = styled.div`
  position: relative;
  height: 240px;
  width: 240px;
  border-radius: 25%;
  border: 4px solid pink;
  overflow: hidden;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: coral;
  align-items: center;
  cursor: pointer;
  transition: 4s all ease-in-out;
  bottom: 0px;

  &.opened {
    bottom: 400px;
  }
`;

function CirclePicture(props) {
  return (
    <CirclePictureFrame>
      <img style={{ width: "100%" }} alt={props.alt} src={props.src} />
    </CirclePictureFrame>
  );
}

const CirclePictureFrame = styled.div`
  height: 240px;
  width: 240px;
  border-radius: 50%;
  border: 10px solid pink;
  overflow: hidden;
  margin: 10px;
`;

const Frame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const AppFrame = styled.div`
  min-height: 100vh;
  width: calc(100vw - 80px);
  background-color: coral;
  padding-bottom: 40px;
  padding-right: 40px;
  padding-left: 40px;
  overflow: hidden;
`;

export default App;
